.camping-images {
    display: flex;
    margin-bottom: 30px;
}
    .camping-images-preview {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .camping-image-preview {
            margin-bottom: 5px;
            width: 50px;
            border-radius: 4px;
        }
            .camping-image-preview-button {
                cursor: pointer;
                padding: 0px;
                border: none;
                background: none;
            }
            button:focus { outline: none; }

            .camping-image-preview-selected {
                border: 3px solid #00695c;
                border-radius: 4px;
            }
    .camping-image {
        min-height: 200px;
        margin-bottom: 5px;
        margin-left: 5px;
        width: 800px;
        border-radius: 4px;
        object-fit: cover;
    }

@media only screen and (max-width: 1200px) {

    .camping-image {
        width: 90%;
    }
    
}

@media only screen and (max-width: 750px) {

    .camping-image {
        width: 80%;
    }
    
}