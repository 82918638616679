.camping-preview {
    width: 600px;
    margin: 10px;
    display: flex;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #282c34;
}

    .camping-preview-image {
        width: 50%;
        margin: 0px 20px 0px 0px;
        border-radius: 4px;
        object-fit: cover;
    }

    .camping-preview-text {
        display: flex;
        flex-direction: column;
        margin: 10px 0px 20px 0px;
    }

        .camping-preview-title {
            font-size: 24px;
            font-weight: 500;
            line-height: 33px;
            margin-bottom: 20px;
        }

        .camping-preview-priceandbooking {
            display: flex;
            font-size: 18px;
            line-height: 24px;
        }
            .camping-preview-price {
                margin-right: 10px;
            }
            .camping-preview-booking{
                font-weight: 800;
            }

        .camping-preview-icons {
            margin: 20px 0px 20px 0px;
            display: flex;
            flex-wrap: wrap;
        }
        
        .camping-preview-button {
            background-color: #00695c;
            color: white;
            border-radius: 10px;
            padding: 7px;
            font-weight: 700;
            font-size: 24px;
            width: 120px;
            cursor: pointer;
            text-align: center;
        }

@media only screen and (max-width: 750px) {

    .camping-preview {
        width: 500px;
    }

}

@media only screen and (max-width: 550px) {

    .camping-preview {
        width: 420px;
        margin: 10px 5px;
        /* padding: 0px;
        border: 1px solid #282c34;
        border-left: 0px;
        border-right: 0px;
        border-radius: 0px; */
    }
    .camping-preview-image {
        border-radius: 0px;
    }
    
    .camping-preview-title {
        font-size: 20px;
        line-height: 25px;
    }

    .camping-preview-priceandbooking {
        font-size: 14px;
        line-height: 18px;
    }

    .camping-preview-button {
        font-size: 15px;
    }

}

@media only screen and (max-width: 450px) {

    .camping-preview {
        width: 345px;
    }

    .camping-preview-title {
        font-size: 15px;
        line-height: 15px;
    }

    .camping-preview-priceandbooking {
        font-size: 12px;
        line-height: 13px;
    }

    .camping-preview-button {
        font-size: 13px;
        width: 80%;
    }

}

@media only screen and (max-width: 370px) {

    .camping-preview {
        width: 300px;
    }

    .camping-preview-title {
        font-size: 13px;
        line-height: 13px;
    }

    .camping-preview-priceandbooking {
        font-size: 10px;
        line-height: 11px;
    }

}