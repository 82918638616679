.feedback-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0px 300px 0px;
}
.email-image {
    width: 200px;
}
.feedback-message {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    margin-top: 30px;
}

.error-message {
    font-size: 20px;
    text-align: center;
    color: red;
    font-weight: 600;
    margin-top: 50px;
}
.error-field {
    background-color: rgb(255, 108, 108);
}

.signup-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0px;
}
.signup-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 315px;
}
.signup-calltoaction {
    margin: 0px 0px 30px 0px;
    font-size: 16px;
    font-weight: 600;
}

.hr {
    background-color: rgb(170, 170, 170);
    width: 100%;
    padding: 0.5px 8px;
    margin-bottom: 30px;
}

.input-field {
    padding: 13px 8px;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid;
    font-size: 18px;
}

.signup-checkbox {
    width: 100%;
}

  .signup-checkbox {
    display: flex;
    justify-content: space-between;
    align-self: center;
    margin: 5px 0px;
  }

  .signup-checkbox-box {
      cursor: pointer;
  }

  .auth-button {
    background-color: #00695c;
    color: white;
    padding: 13px 8px;
    font-weight: bold;
    width: 100%;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
  }
  .auth-button:hover {
    background-color: #46a398;
  }

.policies-signup {
    margin: 30px 0px 20px 0px;
    font-size: 13px;
}
.policy-signup {
    margin: 0px 5px;
}

.link-auth {
    font-weight: 700;
}
.small-margin-top {
    margin-top: 20px;
    font-size: 15px;
}
.margin-top {
    margin-top: 40px;
}
.reset {
    margin-bottom: 100px;
}

@media only screen and (max-width: 375px) {
    .signup-form {
        width: 280px;
    }
    .signup-checkbox {
        font-size: 14px;
    }
    .policies-signup {
        font-size: 11px;
    }
}

@media only screen and (max-width: 333px) {
    .signup-form {
        width: 250px;
    }
    .signup-checkbox {
        font-size: 13px;
    }
    .policies-signup {
        font-size: 10px;
    }
}