.search-filtering {
    background-color: #282c34;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 20px;
}

.search-list {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0px 50px 0px;
}

.noresult-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
    .noresult-message {
        font-size: 24px;
        font-weight: 500;
        line-height: 33px;
        margin: 20px 0px 300px 0px;
    }
    .noresult-image {
        height: 100px;
        margin-top: 200px;
    }

@media only screen and (max-width: 750px) {

    .search-filtering {
        flex-direction: column;
    }

}