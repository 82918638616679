.select-input {
    height: 46px;
    width: 310px;
    font-size: 20px;
    line-height: 25px;
    cursor: pointer;
    box-shadow: 0px 2px 4px black;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    border-radius: 10px;
}
select{
    text-align-last: center;
}