.camping-page {
    padding: 10px 30px 45px 30px;
    display: flex;
    flex-direction: column;
}

    .camping-title {
        font-size: 48px;
        font-weight: 500;
        margin: 40px 0px 35px 60px;
    }

    .camping-intro-section {
        display: flex;
    }
        .camping-info {
            display: flex;
            flex-direction: column;
            margin: 0px 60px 0px 30px;
        }
        .camping-location-section{
            margin: 0px 60px 20px 60px;
        }
            .camping-section-title {
                font-size: 30px;
                font-weight: 500;
                margin-bottom: 20px;
            }
            .camping-description, .camping-website, 
            .camping-contacts, .camping-tents, 
            .camping-nights, .camping-accessibility, 
            .camping-price, .camping-region,
            .camping-address, .camping-coordinates {
                margin-bottom: 10px;
                line-height: 160%;
                font-size: 24px;
                font-weight: 400;
            }
            .camping-char-title {
                font-size: 30px;
                font-weight: 500;
                margin-top: 30px;
                margin-bottom: 20px;
            }
                .camping-characteristics {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 60px;
                }
            .camping-map {
                margin-bottom: 50px;
                width: 100%;
                height: 550px;
            }


@media only screen and (max-width: 1200px) {

    .camping-page {
        padding: 10px 50px 45px 50px;
    }

    .camping-intro-section {
        flex-direction: column;
    }

    .camping-info, .camping-location-section {
        margin: 0px 60px 0px 60px;
        width: 90%;
    }

    .camping-section-title {
        margin-top: 30px;
    }
    
    .camping-char-title {
        margin-top: 50px;
    }
        .camping-characteristics {
            margin-bottom: 30px;
        }

}

@media only screen and (max-width: 750px) {

    .camping-page {
        padding: 10px 30px 45px 30px;
    }

    .camping-title {
        font-size: 40px;
    }

    .camping-section-title, .camping-char-title  {
        font-size: 30px;
    }

    .camping-info, .camping-location-section {
        width: auto;
        margin: 0px 60px 0px 60px;
    }

    .camping-description, .camping-website, 
    .camping-contacts, .camping-tents, 
    .camping-nights, .camping-accessibility, 
    .camping-price, .camping-region,
    .camping-address, .camping-coordinates {
        font-size: 20px;
    }

    .camping-map {
        height: 350px;
    }

}

@media only screen and (max-width: 500px) {

    .camping-title {
        font-size: 25px;
        margin-top: 25px;
        margin-left: 20px;
    }

    .camping-page {
        padding: 5px 20px 45px 20px;
    }

    .camping-info, .camping-location-section {
        margin: 0px 10px;
    }

    .camping-section-title, .camping-char-title  {
        font-size: 20px;
    }

    .camping-description, .camping-website, 
    .camping-contacts, .camping-tents, 
    .camping-nights, .camping-accessibility, 
    .camping-price, .camping-region,
    .camping-address, .camping-coordinates {
        font-size: 15px;
    }

}