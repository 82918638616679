.pagination {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

    .pagination-number {
        /* margin: 20px; */
        color: #00695c;
        padding: 0.5rem;
        border: 1px solid #00695c;
        margin: 0 1rem;
    }

    .pagination-text {
        width: 80px;
        text-align: center;
    }

    .pagination-number-current {
        background: #00695c;
        color: white;
    }

@media only screen and (max-width: 450px) {

    .pagination-text {
        display: none;
    }

}