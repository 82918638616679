@import url('https://fonts.googleapis.com/css?family=Open+Sans:bold,normal&display=swap');
@import url('https://fonts.googleapis.com/css?family=Quicksand:bold,normal&display=swap');

.App {
  text-align: center;
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
}

body {
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
}

.hide {
  display: none;
}

a {
  text-decoration: none;
}

button { 
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
} 

.footer {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 40px 0px 20px 0px;
}

  .footer-element {
    margin-bottom: 20px;
    color: white;
  }