.social-section {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }
  .social {
    margin: 15px;
    text-align: center;
  }
  
  .logo-name{
    font-size: 25px;
    margin-top: 40px;
  }

.policies {
  margin-top: 13px;
  color: white;
}