.home-background {
    text-align: center;
    position: relative;
}
  
.home-background-image {
    min-height: 360px;
    width: 100%;
    margin-bottom: -3px;
}

.home-background-text {
    position: absolute;
    margin-top: 30px;
    width: 100%;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
}

    .home-background-form {
        margin-top: 90px;
        display: flex;
        justify-content: center;
    }

        .home-background-button {
            background-color: #00695c;
            color: white;
            border-radius: 10px;
            padding: 8px;
            font-weight: bold;
            width: 150px;
            cursor: pointer;
        }

.home-wildcampingintro {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 50px;
}

    .home-question {
        margin: 90px 40px 90px 40px;
        font-size: 40px;
        font-weight: 500;
    }

    .home-paragraph {
        margin-bottom: 60px;
        width: 50%;
        line-height: 160%;
        font-size: 24px;
        font-weight: 400;
    }


@media only screen and (max-width: 750px) {
    
    .home-background-text {
        font-size: 20px;
        width: 90%;
        position: absolute;
    }

    .home-question {
        margin: 40px;
        font-size: 30px;
    }

    .home-paragraph {
        font-size: 20px;
        margin-bottom: 40px;
        width: 70%;
    }
    
}