.App-header {
    background-color: #282c34;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    padding: 0px 20px;
  }

    .header-text {
      color: white;
    }
    .current {
      font-weight: bold;
    }

    .desktop {
        display: flex;
        align-items: center;
    }
        .header-link {
            padding: 20px;
            font-size: 20px;
            cursor: pointer;
        }

.hamburger, .mobile {
  display: none;
}

.nav {
  font-family: 'Quicksand';
  font-size: 14px;
  line-height: 40px;
  color: white;
}

@media only screen and (max-width: 650px) {
  .App-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .mobile {
    /* display: block; */
    display: flex;
    justify-content: center;
    background-color: transparent;
    box-shadow: none;
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }

  .desktop {
      display: none;
  }

  .hamburger {
      display: block;
      float: right;
      cursor: pointer;
      position: absolute;
      top: 1.1em;
      right: 0;
      margin-right: 1em;
  }
  
}