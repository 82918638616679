.icon-section {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

    .icon{
        font-size: 40px;
        text-align: center;
    }

    .details {
        margin-left: 20px;
        margin-right: 40px;
        font-size: 24px;
        font-weight: 400;
    }

@media only screen and (max-width: 750px) {

    .details {
        font-size: 20px;
        margin: 15px;
    }

    .icon{
        font-size: 30px;
    }

}

@media only screen and (max-width: 500px) {

    .details {
        font-size: 15px;
    }

    .icon{
        font-size: 25px;
    }

}