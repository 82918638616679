@import url(https://fonts.googleapis.com/css?family=Open+Sans:bold,normal&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quicksand:bold,normal&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
}

body {
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
}

.hide {
  display: none;
}

a {
  text-decoration: none;
}

button { 
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
} 

.footer {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 40px 0px 20px 0px;
}

  .footer-element {
    margin-bottom: 20px;
    color: white;
  }
.App-header {
    background-color: #282c34;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    padding: 0px 20px;
  }

    .header-text {
      color: white;
    }
    .current {
      font-weight: bold;
    }

    .desktop {
        display: flex;
        align-items: center;
    }
        .header-link {
            padding: 20px;
            font-size: 20px;
            cursor: pointer;
        }

.hamburger, .mobile {
  display: none;
}

.nav {
  font-family: 'Quicksand';
  font-size: 14px;
  line-height: 40px;
  color: white;
}

@media only screen and (max-width: 650px) {
  .App-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .mobile {
    /* display: block; */
    display: flex;
    justify-content: center;
    background-color: transparent;
    box-shadow: none;
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }

  .desktop {
      display: none;
  }

  .hamburger {
      display: block;
      float: right;
      cursor: pointer;
      position: absolute;
      top: 1.1em;
      right: 0;
      margin-right: 1em;
  }
  
}
.social-section {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }
  .social {
    margin: 15px;
    text-align: center;
  }
  
  .logo-name{
    font-size: 25px;
    margin-top: 40px;
  }

.policies {
  margin-top: 13px;
  color: white;
}
.home-background {
    text-align: center;
    position: relative;
}
  
.home-background-image {
    min-height: 360px;
    width: 100%;
    margin-bottom: -3px;
}

.home-background-text {
    position: absolute;
    margin-top: 30px;
    width: 100%;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
}

    .home-background-form {
        margin-top: 90px;
        display: flex;
        justify-content: center;
    }

        .home-background-button {
            background-color: #00695c;
            color: white;
            border-radius: 10px;
            padding: 8px;
            font-weight: bold;
            width: 150px;
            cursor: pointer;
        }

.home-wildcampingintro {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 50px;
}

    .home-question {
        margin: 90px 40px 90px 40px;
        font-size: 40px;
        font-weight: 500;
    }

    .home-paragraph {
        margin-bottom: 60px;
        width: 50%;
        line-height: 160%;
        font-size: 24px;
        font-weight: 400;
    }


@media only screen and (max-width: 750px) {
    
    .home-background-text {
        font-size: 20px;
        width: 90%;
        position: absolute;
    }

    .home-question {
        margin: 40px;
        font-size: 30px;
    }

    .home-paragraph {
        font-size: 20px;
        margin-bottom: 40px;
        width: 70%;
    }
    
}
.search-filtering {
    background-color: #282c34;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 20px;
}

.search-list {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0px 50px 0px;
}

.noresult-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
    .noresult-message {
        font-size: 24px;
        font-weight: 500;
        line-height: 33px;
        margin: 20px 0px 300px 0px;
    }
    .noresult-image {
        height: 100px;
        margin-top: 200px;
    }

@media only screen and (max-width: 750px) {

    .search-filtering {
        flex-direction: column;
    }

}
.icon-section {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

    .icon{
        font-size: 40px;
        text-align: center;
    }

    .details {
        margin-left: 20px;
        margin-right: 40px;
        font-size: 24px;
        font-weight: 400;
    }

@media only screen and (max-width: 750px) {

    .details {
        font-size: 20px;
        margin: 15px;
    }

    .icon{
        font-size: 30px;
    }

}

@media only screen and (max-width: 500px) {

    .details {
        font-size: 15px;
    }

    .icon{
        font-size: 25px;
    }

}
.camping-preview {
    width: 600px;
    margin: 10px;
    display: flex;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #282c34;
}

    .camping-preview-image {
        width: 50%;
        margin: 0px 20px 0px 0px;
        border-radius: 4px;
        object-fit: cover;
    }

    .camping-preview-text {
        display: flex;
        flex-direction: column;
        margin: 10px 0px 20px 0px;
    }

        .camping-preview-title {
            font-size: 24px;
            font-weight: 500;
            line-height: 33px;
            margin-bottom: 20px;
        }

        .camping-preview-priceandbooking {
            display: flex;
            font-size: 18px;
            line-height: 24px;
        }
            .camping-preview-price {
                margin-right: 10px;
            }
            .camping-preview-booking{
                font-weight: 800;
            }

        .camping-preview-icons {
            margin: 20px 0px 20px 0px;
            display: flex;
            flex-wrap: wrap;
        }
        
        .camping-preview-button {
            background-color: #00695c;
            color: white;
            border-radius: 10px;
            padding: 7px;
            font-weight: 700;
            font-size: 24px;
            width: 120px;
            cursor: pointer;
            text-align: center;
        }

@media only screen and (max-width: 750px) {

    .camping-preview {
        width: 500px;
    }

}

@media only screen and (max-width: 550px) {

    .camping-preview {
        width: 420px;
        margin: 10px 5px;
        /* padding: 0px;
        border: 1px solid #282c34;
        border-left: 0px;
        border-right: 0px;
        border-radius: 0px; */
    }
    .camping-preview-image {
        border-radius: 0px;
    }
    
    .camping-preview-title {
        font-size: 20px;
        line-height: 25px;
    }

    .camping-preview-priceandbooking {
        font-size: 14px;
        line-height: 18px;
    }

    .camping-preview-button {
        font-size: 15px;
    }

}

@media only screen and (max-width: 450px) {

    .camping-preview {
        width: 345px;
    }

    .camping-preview-title {
        font-size: 15px;
        line-height: 15px;
    }

    .camping-preview-priceandbooking {
        font-size: 12px;
        line-height: 13px;
    }

    .camping-preview-button {
        font-size: 13px;
        width: 80%;
    }

}

@media only screen and (max-width: 370px) {

    .camping-preview {
        width: 300px;
    }

    .camping-preview-title {
        font-size: 13px;
        line-height: 13px;
    }

    .camping-preview-priceandbooking {
        font-size: 10px;
        line-height: 11px;
    }

}
.select-input {
    height: 46px;
    width: 310px;
    font-size: 20px;
    line-height: 25px;
    cursor: pointer;
    box-shadow: 0px 2px 4px black;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    border-radius: 10px;
}
select{
    text-align-last: center;
}
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

    .pagination-number {
        /* margin: 20px; */
        color: #00695c;
        padding: 0.5rem;
        border: 1px solid #00695c;
        margin: 0 1rem;
    }

    .pagination-text {
        width: 80px;
        text-align: center;
    }

    .pagination-number-current {
        background: #00695c;
        color: white;
    }

@media only screen and (max-width: 450px) {

    .pagination-text {
        display: none;
    }

}
.filter-button {
    background-color: white;
    cursor: pointer;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
    height: 46px;
    width: 310px;
    font-size: 20px;
    line-height: 25px;
    cursor: pointer;
    box-shadow: 0px 2px 4px black;
    border-radius: 10px;
}

.filter-section {
    position: absolute;
    top: 75px;
    right: 180px;
    width: 330px;
    height: 300px;
    background-color: white;
    color: #282c34;
    font-size: 20px;
    line-height: 25px;
    box-shadow: 0px 2px 4px black;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 10px;
}

.filter-checkbox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.done-button {
    margin-top: 10px;
    cursor: pointer;
    border: 2px solid #282c34;
    text-align: center;
    border-radius: 10px;
    padding: 7px;
    font-weight: 500;
    font-size: 24px;
    cursor: pointer;
}

@media only screen and (max-width: 1320px) {

    .filter-section {
        right: 130px;
    }

}

@media only screen and (max-width: 1050px) {

    .filter-section {
        right: 90px;
    } 

}

@media only screen and (max-width: 750px) {

    .filter-button {
        margin: 10px 0px 20px 0px;
    }

    .filter-section {
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        width: 250px;
    } 

}



.camping-images {
    display: flex;
    margin-bottom: 30px;
}
    .camping-images-preview {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .camping-image-preview {
            margin-bottom: 5px;
            width: 50px;
            border-radius: 4px;
        }
            .camping-image-preview-button {
                cursor: pointer;
                padding: 0px;
                border: none;
                background: none;
            }
            button:focus { outline: none; }

            .camping-image-preview-selected {
                border: 3px solid #00695c;
                border-radius: 4px;
            }
    .camping-image {
        min-height: 200px;
        margin-bottom: 5px;
        margin-left: 5px;
        width: 800px;
        border-radius: 4px;
        object-fit: cover;
    }

@media only screen and (max-width: 1200px) {

    .camping-image {
        width: 90%;
    }
    
}

@media only screen and (max-width: 750px) {

    .camping-image {
        width: 80%;
    }
    
}
.camping-page {
    padding: 10px 30px 45px 30px;
    display: flex;
    flex-direction: column;
}

    .camping-title {
        font-size: 48px;
        font-weight: 500;
        margin: 40px 0px 35px 60px;
    }

    .camping-intro-section {
        display: flex;
    }
        .camping-info {
            display: flex;
            flex-direction: column;
            margin: 0px 60px 0px 30px;
        }
        .camping-location-section{
            margin: 0px 60px 20px 60px;
        }
            .camping-section-title {
                font-size: 30px;
                font-weight: 500;
                margin-bottom: 20px;
            }
            .camping-description, .camping-website, 
            .camping-contacts, .camping-tents, 
            .camping-nights, .camping-accessibility, 
            .camping-price, .camping-region,
            .camping-address, .camping-coordinates {
                margin-bottom: 10px;
                line-height: 160%;
                font-size: 24px;
                font-weight: 400;
            }
            .camping-char-title {
                font-size: 30px;
                font-weight: 500;
                margin-top: 30px;
                margin-bottom: 20px;
            }
                .camping-characteristics {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 60px;
                }
            .camping-map {
                margin-bottom: 50px;
                width: 100%;
                height: 550px;
            }


@media only screen and (max-width: 1200px) {

    .camping-page {
        padding: 10px 50px 45px 50px;
    }

    .camping-intro-section {
        flex-direction: column;
    }

    .camping-info, .camping-location-section {
        margin: 0px 60px 0px 60px;
        width: 90%;
    }

    .camping-section-title {
        margin-top: 30px;
    }
    
    .camping-char-title {
        margin-top: 50px;
    }
        .camping-characteristics {
            margin-bottom: 30px;
        }

}

@media only screen and (max-width: 750px) {

    .camping-page {
        padding: 10px 30px 45px 30px;
    }

    .camping-title {
        font-size: 40px;
    }

    .camping-section-title, .camping-char-title  {
        font-size: 30px;
    }

    .camping-info, .camping-location-section {
        width: auto;
        margin: 0px 60px 0px 60px;
    }

    .camping-description, .camping-website, 
    .camping-contacts, .camping-tents, 
    .camping-nights, .camping-accessibility, 
    .camping-price, .camping-region,
    .camping-address, .camping-coordinates {
        font-size: 20px;
    }

    .camping-map {
        height: 350px;
    }

}

@media only screen and (max-width: 500px) {

    .camping-title {
        font-size: 25px;
        margin-top: 25px;
        margin-left: 20px;
    }

    .camping-page {
        padding: 5px 20px 45px 20px;
    }

    .camping-info, .camping-location-section {
        margin: 0px 10px;
    }

    .camping-section-title, .camping-char-title  {
        font-size: 20px;
    }

    .camping-description, .camping-website, 
    .camping-contacts, .camping-tents, 
    .camping-nights, .camping-accessibility, 
    .camping-price, .camping-region,
    .camping-address, .camping-coordinates {
        font-size: 15px;
    }

}
.feedback-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0px 300px 0px;
}
.email-image {
    width: 200px;
}
.feedback-message {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    margin-top: 30px;
}

.error-message {
    font-size: 20px;
    text-align: center;
    color: red;
    font-weight: 600;
    margin-top: 50px;
}
.error-field {
    background-color: rgb(255, 108, 108);
}

.signup-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0px;
}
.signup-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 315px;
}
.signup-calltoaction {
    margin: 0px 0px 30px 0px;
    font-size: 16px;
    font-weight: 600;
}

.hr {
    background-color: rgb(170, 170, 170);
    width: 100%;
    padding: 0.5px 8px;
    margin-bottom: 30px;
}

.input-field {
    padding: 13px 8px;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid;
    font-size: 18px;
}

.signup-checkbox {
    width: 100%;
}

  .signup-checkbox {
    display: flex;
    justify-content: space-between;
    align-self: center;
    margin: 5px 0px;
  }

  .signup-checkbox-box {
      cursor: pointer;
  }

  .auth-button {
    background-color: #00695c;
    color: white;
    padding: 13px 8px;
    font-weight: bold;
    width: 100%;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
  }
  .auth-button:hover {
    background-color: #46a398;
  }

.policies-signup {
    margin: 30px 0px 20px 0px;
    font-size: 13px;
}
.policy-signup {
    margin: 0px 5px;
}

.link-auth {
    font-weight: 700;
}
.small-margin-top {
    margin-top: 20px;
    font-size: 15px;
}
.margin-top {
    margin-top: 40px;
}
.reset {
    margin-bottom: 100px;
}

@media only screen and (max-width: 375px) {
    .signup-form {
        width: 280px;
    }
    .signup-checkbox {
        font-size: 14px;
    }
    .policies-signup {
        font-size: 11px;
    }
}

@media only screen and (max-width: 333px) {
    .signup-form {
        width: 250px;
    }
    .signup-checkbox {
        font-size: 13px;
    }
    .policies-signup {
        font-size: 10px;
    }
}
